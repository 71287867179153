import { Component, OnInit,ViewChild ,ElementRef} from '@angular/core';
import { ProductService } from 'app/service/products.service';
import { FormBuilder, FormGroup, Validators, FormArray,FormControl } from '@angular/forms';
import Swal from 'sweetalert2';
import { MatTableDataSource } from '@angular/material/table';
import 'rxjs/Rx' ;
import { MatPaginator } from '@angular/material/paginator';
import { UserService } from 'app/service/user.service';
import { ReplaySubject, Subject } from 'rxjs';
import { take ,takeUntil} from 'rxjs/operators';
export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}
interface CountryData {

  Id: number;
  label: string;
  product_name: string
  value: number;
}


const ELEMENT_DATA: PeriodicElement[] = [
  
];
@Component({
  selector: 'app-generateqrcode',
  templateUrl: './generateqrcode.component.html',
  styleUrls: ['./generateqrcode.component.css']
})
export class GenerateqrcodeComponent implements OnInit {



  @ViewChild('modal') modal;
  public pageSize = 10;
public currentPage = 0;
public totalSize = 0;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  productData: any;
  generateqrcode:FormGroup;
  public ProductFilterCtrl: FormControl = new FormControl();
  image: any;
  editedData: any;
 buttonName='Generate QR Code'
  fileName: any;
  file_name='Choose Image';
  displayedColumns: string[] = ['position', 'Product Name', 'Quantity', 'Action'];
  dataSource = new MatTableDataSource(ELEMENT_DATA);
  userList: any;
  QrCodeData: any;
  qrcodeflag:boolean=false;
  downloadqrcode:boolean=false;
  GenerateQrCode:boolean=true;
  url: any;
  checked = false;
  private _onDestroy = new Subject<void>();
  public filteredProduct: ReplaySubject<CountryData[]> = new ReplaySubject<CountryData[]>(1);
  date: boolean;
  NotificationData: any;
  constructor(public productservice:ProductService,public formBuilder: FormBuilder,public userservice:UserService) { }

  ngOnInit(): void {

    this.dataSource.paginator = this.paginator;
    
    this.generateqrcode = this.formBuilder.group({
      
    
      Product: ['',[Validators.required]],
      quantity: ['',[Validators.required]],
  
     
     
    });
   
    this.getUserList();
    this.ProductFilterCtrl.valueChanges.pipe(takeUntil(this._onDestroy))
    .subscribe(() =>{
      this.filterCountry();
    })
    this.getQrCodeHistoryData();

    
  }
  getUserList(){
    this.productservice.productList().subscribe(result=>{
      this.userList=result.data
      this.filteredProduct.next(this.userList.slice());

    })
  }
  radioChange(value){
console.log(value)
if(value== "now"){
  this.date=false;
}else{
  this.date=true;
}


  }
  convertDate(inputFormat) {
    function pad(s) { return (s < 10) ? '0' + s : s; }
    var d = new Date(inputFormat);
    //return ([ d.getFullYear(),pad(d.getMonth()+1),pad(d.getDate())].join('-'));
    // return ([pad(d.getFullYear(),pad(d.getMonth() + 1)d.getDate())].join('-'));
    return ([d.getFullYear(), pad(d.getMonth() + 1) ,pad(d.getDate())].join('-'));

  }
  private filterCountry() {
    console.log(this.userList)
    if (!this.userList) {
      return;
    }
  
    // get the search keyword
    console.log(this.ProductFilterCtrl.value)
    let search = this.ProductFilterCtrl.value;
  
    if (!search) {
      this.filteredProduct.next(this.userList.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
  
  
    
    this.filteredProduct.next(
      this.userList.filter(Product => Product.product_name.toLowerCase().indexOf(search) > -1)
    );
  }
  onFileChange(event){
    // alert(1)
    const formData = new FormData();
    formData.append('file', event.target.files[0]);

    this.productservice.uploadDocuments(formData).subscribe(result=>{
      this.image=result.image_path;
      this.file_name=result.file_name;
      // console.log(this.image)

    })
  }
  saveqrCode(){
    if(this.generateqrcode.status == 'INVALID'){
      this.generateqrcode.markAllAsTouched();
      Swal.fire('','Please fill all mandatory fields','error');
      return false;
    }else {
      this.qrcodeflag=true;
    console.log(this.generateqrcode.value)
    this.modal.nativeElement.click();
    this.productservice.saveqrCode(this.generateqrcode.value).subscribe(result=>{
      
        
      this.generateqrcode.reset();
    

this.url = result.url
this.getQrCodeHistoryData();
this.qrcodeflag=false;
    })
  }
  // console.log(this.generateqrcode.value)
  }

  downloadMyFile(url) {
    let a = document.createElement("a");
    document.body.appendChild(a);
    a.href = url;
    // a.download = downloadName;
    a.click();
    document.body.removeChild(a);
  }

//   downloadMyFile(link){
// console.log(link)
// const blob = new Blob([link], { type: 'application/zip' });
//   const url= window.URL.createObjectURL(blob);
//   window.open(url);
//   }
  getQrCodeHistoryData(){
    this.buttonName == 'Save'
    this.productservice.getQrCodeData().subscribe(result=>{
      // result.data.forEach(element => {
      //   ELEMENT_DATA.push(element)
      // });
     
      // console.log(ELEMENT_DATA)
      this.dataSource = new MatTableDataSource<any>(result.data);

      this.dataSource.paginator = this.paginator;
     this.NotificationData=result.data;
     this.totalSize = this.NotificationData.length;
     this.iterator();

    })

  }

  public handlePage(e: any) {
    this.currentPage = e.pageIndex;
    this.pageSize = e.pageSize;
    this.iterator();
  }
  private iterator() {
    const end = (this.currentPage + 1) * this.pageSize;
    const start = this.currentPage * this.pageSize;
    const part = this.NotificationData.slice(start, end);
    this.dataSource = part;
  }

  deleteQrCode(id){

    Swal.fire({
      title: "Are you sure?",
      text: "you want to Delete it?",
      // type: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No"
    }).then(result => {
      if (result.value) {
        this.productservice.deleteQrCode(id).subscribe(result=>{
          this.getQrCodeHistoryData();
        })
    }else if (result.dismiss === Swal.DismissReason.cancel) {
      Swal.fire("Cancelled","", "error");
    }
        
                });

    

  }
}
