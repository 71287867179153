import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';


import { AppRoutingModule } from './app.routing';
import { ComponentsModule } from './components/components.module';

import { AppComponent } from './app.component';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { DashboardComponent } from './dashboard/dashboard.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { TableListComponent } from './table-list/table-list.component';
import { TypographyComponent } from './typography/typography.component';
import { IconsComponent } from './icons/icons.component';
import { MapsComponent } from './maps/maps.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { UpgradeComponent } from './upgrade/upgrade.component';
import {
  AgmCoreModule
} from '@agm/core';
import { MaterialuiModule } from "./materialui/materialui.module";
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { UsersComponent } from './users/users.component';
import { ProductsComponent } from './products/products.component';
import { RedeemComponent } from './redeem/redeem.component';
import { AuthGuard } from './service/auth.guard';
import { JwtService } from './service/jwt.service';
import { GiftComponent } from './gift/gift.component';
import { WhatsnewComponent } from './whatsnew/whatsnew.component';
import { GiftHistoryComponent } from './gift-history/gift-history.component';
import { ProductredeemComponent } from './productredeem/productredeem.component';
import { NotificationComponent } from './notification/notification.component';
import { BestreportComponent } from './bestreport/bestreport.component';
import { GenerateqrcodeComponent } from './generateqrcode/generateqrcode.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { LoginComponent } from '../app/login/login.component';

@NgModule({
  imports: [
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ComponentsModule,
    NgxMatSelectSearchModule,
    RouterModule,
    AppRoutingModule,
    MDBBootstrapModule.forRoot(),
    MaterialuiModule
    
  ],
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    UsersComponent,
    ProductsComponent,
    GiftComponent,
    WhatsnewComponent,
    GiftHistoryComponent,
    ProductredeemComponent,
    NotificationComponent,
    BestreportComponent,
    GenerateqrcodeComponent,
    RedeemComponent,LoginComponent

  ],
  providers: [AuthGuard,JwtService],
  bootstrap: [AppComponent]
})
export class AppModule { }
