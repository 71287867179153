import { Component, OnInit,ViewChild ,ElementRef} from '@angular/core';
import { ProductService } from 'app/service/products.service';
import { FormBuilder, FormGroup, Validators, FormArray,FormControl } from '@angular/forms';
import Swal from 'sweetalert2';
import { MatTableDataSource } from '@angular/material/table';

import { MatPaginator } from '@angular/material/paginator';
import { UserService } from 'app/service/user.service';
import { ReplaySubject, Subject } from 'rxjs';
import { take ,takeUntil} from 'rxjs/operators';
export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}
interface CountryData {

  Id: number;
  label: string;
  name: string
  value: number;
}

interface Hour {
  value: string;
  viewValue: string;
}
interface Minute {
  value: string;
  viewValue: string;
}
const ELEMENT_DATA: PeriodicElement[] = [
  
];
@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.css']
})
export class NotificationComponent implements OnInit {

  hours: Hour[] = [
    {value: '1', viewValue: '1'},
    {value: '2', viewValue: '2'},
    {value: '3', viewValue: '3'},
    {value: '4', viewValue: '4'},
    {value: '5', viewValue: '5'},
    {value: '6', viewValue: '6'},
    {value: '7', viewValue: '7'},
    {value: '8', viewValue: '8'},
    {value: '9', viewValue: '9'},
    {value: '10', viewValue: '10'},
    {value: '11', viewValue: '11'},
    {value: '12', viewValue: '12'},
    {value: '13', viewValue: '13'},
    {value: '14', viewValue: '14'},
    {value: '15', viewValue: '15'},
    {value: '16', viewValue: '16'},
    {value: '17', viewValue: '17'},
    {value: '18', viewValue: '18'},
    {value: '19', viewValue: '19'},
    {value: '20', viewValue: '20'},
    {value: '21', viewValue: '21'},
    {value: '22', viewValue: '22'},
    {value: '23', viewValue: '23'},
    {value: '24', viewValue: '24'},
  ];

  minutes: Minute[] = [
    {value: '00', viewValue: '00'},
    {value: '15', viewValue: '15'},
    {value: '30', viewValue: '30'},
    {value: '45', viewValue: '45'}

  ];


  @ViewChild('modal') modal;
  public pageSize = 10;
public currentPage = 0;
public totalSize = 0;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  productData: any;
  generateNotification:FormGroup;
  public ProductFilterCtrl: FormControl = new FormControl();
  image: any;
  editedData: any;
 buttonName='Send Notification'
  fileName: any;
  file_name='Choose Image';
  displayedColumns: string[] = ['position', 'Notification Type', 'Title', 'Message', 'Status', 'Action'];
  dataSource = new MatTableDataSource(ELEMENT_DATA);
  userList: any;
  QrCodeData: any;
  qrcodeflag:boolean=false;
  downloadqrcode:boolean=false;
  GenerateQrCode:boolean=true;
  url: any;
  checked = false;
  private _onDestroy = new Subject<void>();
  public filteredProduct: ReplaySubject<CountryData[]> = new ReplaySubject<CountryData[]>(1);
  date: boolean;
  NotificationData: any;
  constructor(public productservice:ProductService,public formBuilder: FormBuilder,public userservice:UserService) { }

  ngOnInit(): void {

    this.dataSource.paginator = this.paginator;
    
    this.generateNotification = this.formBuilder.group({
      
      allUser:[],
      nowSchedule: [],
      Product: [],
      title: ['',[Validators.required]],
      message: ['',[Validators.required]],
      date: [],
      hour: [],
       image:[''],
      minute: []
     
    });
    this.generateNotification.get("nowSchedule").setValue("now")
    this.generateNotification.get("allUser").setValue("0")
    console.log(this.generateNotification.value.nowSchedule)
    this.getUserList();
    this.ProductFilterCtrl.valueChanges.pipe(takeUntil(this._onDestroy))
    .subscribe(() =>{
      this.filterCountry();
    })
    this.getNotificationData();

    this.generateNotification.get("minute").setValue(this.minutes[0].value)
  }
  getUserList(){
    this.userservice.userList().subscribe(result=>{
      this.userList=result.data
      this.filteredProduct.next(this.userList.slice());

    })
  }
  radioChange(value){
console.log(value)
if(value== "now"){
  this.date=false;
}else{
  this.date=true;
}


  }
  convertDate(inputFormat) {
    function pad(s) { return (s < 10) ? '0' + s : s; }
    var d = new Date(inputFormat);
    //return ([ d.getFullYear(),pad(d.getMonth()+1),pad(d.getDate())].join('-'));
    // return ([pad(d.getFullYear(),pad(d.getMonth() + 1)d.getDate())].join('-'));
    return ([d.getFullYear(), pad(d.getMonth() + 1) ,pad(d.getDate())].join('-'));

  }
  private filterCountry() {
    console.log(this.userList)
    if (!this.userList) {
      return;
    }
  
    // get the search keyword
    console.log(this.ProductFilterCtrl.value)
    let search = this.ProductFilterCtrl.value;
  
    if (!search) {
      this.filteredProduct.next(this.userList.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
  
  console.log((this.userList))
    
    this.filteredProduct.next(
      this.userList.filter(Product =>
      
         Product.name.toLowerCase().indexOf(search) > -1
         )
    );
  }
  onFileChange(event){
    // alert(1)
    const formData = new FormData();
    formData.append('file', event.target.files[0]);

    this.productservice.uploadDocuments(formData).subscribe(result=>{
      this.image=result.image_path;
      this.file_name=result.file_name;
      // console.log(this.image)

    })
  }
  saveNotification(){
    if(this.generateNotification.status == 'INVALID'){
      this.generateNotification.markAllAsTouched();
      Swal.fire('','Please fill all mandatory fields','error');
      return false;
    }else {
      this.qrcodeflag=true;
    console.log(this.generateNotification.value)
    this.modal.nativeElement.click();
    this.getNotificationData();
    this.productservice.createNotification(this.generateNotification.value,this.convertDate(this.generateNotification.value.date),this.image).subscribe(result=>{
      this.getNotificationData();

      this.generateNotification.reset();
      this.qrcodeflag=false;
      this.downloadqrcode=true;
      this.GenerateQrCode=false;
      // this.getQrCodeData();
      // this.getQrCodeData();
console.log(result)
this.url = result.url
this.getNotificationData();
    })
  }
  // console.log(this.generateqrcode.value)
  }


  getNotificationData(){
    this.buttonName == 'Save'
    this.productservice.getNotificationData().subscribe(result=>{
      // result.data.forEach(element => {
      //   ELEMENT_DATA.push(element)
      // });
     
      // console.log(ELEMENT_DATA)
      this.dataSource = new MatTableDataSource<any>(result.data);

      this.dataSource.paginator = this.paginator;
     this.NotificationData=result.data;
     this.totalSize = this.NotificationData.length;
     this.iterator();

    })

  }

  public handlePage(e: any) {
    this.currentPage = e.pageIndex;
    this.pageSize = e.pageSize;
    this.iterator();
  }
  private iterator() {
    const end = (this.currentPage + 1) * this.pageSize;
    const start = this.currentPage * this.pageSize;
    const part = this.NotificationData.slice(start, end);
    this.dataSource = part;
  }

  deleteProduct(id){

    Swal.fire({
      title: "Are you sure?",
      text: "you want to Delete it?",
      // type: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No"
    }).then(result => {
      if (result.value) {
        this.productservice.deleteNotification(id).subscribe(result=>{
          this.getNotificationData();
        })
    }else if (result.dismiss === Swal.DismissReason.cancel) {
      Swal.fire("Cancelled","", "error");
    }
        
                });

    

  }
}
