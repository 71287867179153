import { Component, OnInit,ViewChild ,ElementRef} from '@angular/core';
import { ProductService } from 'app/service/products.service';
import { FormBuilder, FormGroup, Validators, FormArray,FormControl } from '@angular/forms';
import Swal from 'sweetalert2';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { UserService } from 'app/service/user.service';
export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}
const ELEMENT_DATA: PeriodicElement[] = [
  
];
@Component({
  selector: 'app-productredeem',
  templateUrl: './productredeem.component.html',
  styleUrls: ['./productredeem.component.css']
})
export class ProductredeemComponent implements OnInit {
  @ViewChild('modal') modal;
  public pageSize = 10;
public currentPage = 0;
public totalSize = 0;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  productData: any;
  Gift:FormGroup;
  image: any;
  editedData: any;
 buttonName='Save'
  fileName: any;
  file_name='Choose Image';
  displayedColumns: string[] = ['position','Product Image','User Name', 'Product Name', 'Product Points','Product Price','Product Redeem Date' ,'Action'];
  dataSource = new MatTableDataSource(ELEMENT_DATA);
  userList: any;
  constructor(public productservice:ProductService,public userservice:UserService) { }

  ngOnInit(): void {

    this.dataSource.paginator = this.paginator;
    this.getProductRedeemList();
    this.getUserList();
  }

  public handlePage(e: any) {
    this.currentPage = e.pageIndex;
    this.pageSize = e.pageSize;
    this.iterator();
  }
  private iterator() {
    const end = (this.currentPage + 1) * this.pageSize;
    const start = this.currentPage * this.pageSize;
    const part = this.productData.slice(start, end);
    this.dataSource = part;
  }
  getProductRedeemList(){
    this.productservice.getProductRedeem().subscribe(result=>{
      this.dataSource = new MatTableDataSource<any>(result.data);

       this.dataSource.paginator = this.paginator;
      this.productData=result.data;
      this.totalSize = this.productData.length;
      this.iterator();

    })

  }
  convertToNum(val){
    // alert(1)
    return parseInt(val);
  }
  getUserList(){
    this.userservice.userList().subscribe(result=>{
      

      this.userList=result.data;
      
    })
  }
  deletereward(id){

    Swal.fire({
      title: "Are you sure?",
      text: "you want to Delete it?",
      // type: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No"
    }).then(result => {
      if (result.value) {
        this.productservice.deletereward(id).subscribe(result=>{
          this.getProductRedeemList();
        })
    }else if (result.dismiss === Swal.DismissReason.cancel) {
      Swal.fire("Cancelled","", "error");
    }
        
                });

    

  }
}
