import { Component, OnInit,ViewChild ,ElementRef} from '@angular/core';
import { ProductService } from 'app/service/products.service';
import { FormBuilder, FormGroup, Validators, FormArray,FormControl } from '@angular/forms';
import Swal from 'sweetalert2';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}
const ELEMENT_DATA: PeriodicElement[] = [
  
];
@Component({
  selector: 'app-gift',
  templateUrl: './gift.component.html',
  styleUrls: ['./gift.component.css']
})
export class GiftComponent implements OnInit {
  @ViewChild('modal') modal;
  public pageSize = 10;
public currentPage = 0;
public totalSize = 0;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  productData: any;
  Gift:FormGroup;
  image: any;
  editedData: any;
 buttonName='Save'
  fileName: any;
  file_name='Choose Image';
  displayedColumns: string[] = ['position','Gift Image', 'Gift Name', 'Gift Name Hindi', 'Gift Description','Gift Description Hindi', 'Gift Points', 'Action'];
  dataSource = new MatTableDataSource(ELEMENT_DATA);
  constructor(public productservice:ProductService,public formBuilder: FormBuilder,) { }

  ngOnInit(): void {

    this.dataSource.paginator = this.paginator;
    this.Gift = this.formBuilder.group({
      id:'',
      gift_name:['',Validators.required],
      gift_descreption:['',Validators.required],
      gift_name_hindi:['',Validators.required],
      gift_descreption_hindi:['',Validators.required],
      gift_points: ['',[Validators.required]],
      gift_image: [''],
     
    });
    this.getGiftList();

  }
  public handlePage(e: any) {
    this.currentPage = e.pageIndex;
    this.pageSize = e.pageSize;
    this.iterator();
  }
  private iterator() {
    const end = (this.currentPage + 1) * this.pageSize;
    const start = this.currentPage * this.pageSize;
    const part = this.productData.slice(start, end);
    this.dataSource = part;
  }
  getGiftList(){
    this.buttonName == 'Save'
    this.productservice.giftList().subscribe(result=>{
      this.dataSource = new MatTableDataSource<any>(result.data);

       this.dataSource.paginator = this.paginator;
      this.productData=result.data;
      this.totalSize = this.productData.length;
      this.iterator();

    })

  }

  onFileChange(event){
    // alert(1)
    const formData = new FormData();
    formData.append('file', event.target.files[0]);

    this.productservice.uploadDocuments(formData).subscribe(result=>{
      this.image=result.image_path;
      this.file_name=result.file_name;
      // console.log(this.image)

    })
  }
 
  saveProduct(){
    // if (!this.Gift.valid) {
    //   console.log('All fields are required.')
    //   return false;
    // }
    
    if(this.Gift.status == 'INVALID'){
      this.Gift.markAllAsTouched();
      Swal.fire('','Please fill all mandatory fields','error');
      return false;
    }else {
  if(this.buttonName == 'Save'){
    this.productservice.GiftProduct(this.Gift.value,this.image).subscribe(result=>{
      this.modal.nativeElement.click();
      this.Gift.reset();
      this.getGiftList();

    })
  }else{
    this.productservice.updateGift(this.Gift.value,this.image).subscribe(result=>{
      this.modal.nativeElement.click();
      this.Gift.reset();
      this.getGiftList();

    })
  }
  }
 
    

  }
  editGift(id){
    if(id == "add"){
      this.buttonName='Save';
      this.Gift.reset();
      this.image="";
      this.file_name="Choose Image";
    }else{
   console.log(id)
   this.buttonName='Update'
this.productservice.editGift(id).subscribe(result=>{
  this.editedData =result.data[0];
  this.Gift.get('gift_name').setValue(this.editedData.product_name)
  this.Gift.get('gift_descreption').setValue(this.editedData.details)
  this.Gift.get('gift_name_hindi').setValue(this.editedData.product_name_hindi)
  this.Gift.get('gift_descreption_hindi').setValue(this.editedData.product_details_hindi)
  this.Gift.get('gift_points').setValue(this.editedData.points)
  this.Gift.get('gift_image').setValue(this.editedData.image)
  this.Gift.get('id').setValue(this.editedData.id)

  this.image=this.editedData.image;
})
    }
  }

  checkValidInputData(event: any,type) {
    var pattern:any;
    if(type=='1'){
        pattern = /[0-9\+\-\ ]/;//validae only number
    }
    if(type=='2'){
       pattern= /^[0-9]*\.?[0-9]*$/;//validae decimal with number 
    }
    if(type=='3'){
      pattern= /^[A-Za-z0-9]+$/;//validae alphanumeric 
    }
    if(type=='4'){
      pattern= /^[A-Za-z0-9 ]+$/;//validae alphanumeric with space
    
    }
    if(type=='5'){
      pattern = /^[(\d{3}\)\s?\d{3}-\d{4}]+$/;
     // pattern = /[0-9\+\/\ ]/;//validae only number with \
    }
    if(type == '6'){
        pattern= /^['A-Za-z0-9\-\\.\\/\']+$/; 
    }
    if(type=='7'){
      pattern= /^[A-Za-z ]+$/;//validae alphabets with space
     // pattern= /^['A-Za-z0-9 \-\ \.\ \:\ \;\ \_\ \%\ \#\ \@\ \%\']+$/;
    }
    if(type=='8'){
       pattern = /^[a-zA-Z \-\']+/; //Only alphabets
    }
    if(type=='10'){
        // pattern =  /^[A-Za-z0-9 #&()]+$/; //for location table
        pattern = /^[A-Za-z0-9#&()_., -]+$/;
    }
    if(type=='11'){
        pattern = /^[0-9 \,\']+$/;
    }
    if(type=='12'){
        pattern= /^['A-Za-z0-9 \-\ \.\ \(\ \)\ \\\\ \#\ \/\ \,\ \&\']+$/;//validae alphanumeric with space ^[a-zA-Z \-\ \.\']+ ............ADDRESS
    }
    if(type=='13'){
        pattern= /^['A-Za-z0-9 \-\ \.\ \&\ \/\']+$/;
    }
    if(type=='14'){
        pattern= /^['A-Za-z0-9  \,\  \/]+$/;
    }
    if(type=='15'){
        pattern= /^['A-Za-z0-9  \,\  \/\#\-\.]+$/;
    }
    if(type=='16'){
      pattern= /^[A-Z]/;
    }
    if(type=='17'){
      pattern= /^['A-Za-z \-\ \/\. ]/ ;
    }
    
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
  deleteGift(id){

    Swal.fire({
      title: "Are you sure?",
      text: "you want to Delete it?",
      // type: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No"
    }).then(result => {
      if (result.value) {
        this.productservice.deleteGift(id).subscribe(result=>{
          this.getGiftList();
        })
    }else if (result.dismiss === Swal.DismissReason.cancel) {
      Swal.fire("Cancelled","", "error");
    }
        
                });

    

  }
}
