import { Component, OnInit,ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray,FormControl } from '@angular/forms';
import { UserService } from 'app/service/user.service';
import Swal from 'sweetalert2';
import { ProductService } from 'app/service/products.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}
const ELEMENT_DATA: PeriodicElement[] = [
  
];
@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {
  @ViewChild('modal') modal;

public pageSize = 10;
public currentPage = 0;
public totalSize = 0;

@ViewChild(MatPaginator) paginator: MatPaginator;

searchText;
buttonName='Save'
user:FormGroup;
  userList: any;
  image: any;
  fileName: any;
  editedData: any;
  file_name='Choose Profile Picture';
  displayedColumns: string[] = ['position','User Image', 'Name', 'Mobile Number', 'Created Date', 'Status', 'User Status','Action'];
  // this.dataSource = new MatTableDataSource<any>(this.taskExpenseList);
  dataSource = new MatTableDataSource(ELEMENT_DATA);
  constructor(public productservice:ProductService,public formBuilder: FormBuilder,public userservice:UserService) { }

  ngOnInit(): void {
    this.dataSource.paginator = this.paginator;
    this.user = this.formBuilder.group({
      id:'',
      name:['',Validators.required],
      mobile_number:['',Validators.required],
      image:[''],
   
     
    });

    this.getUserList();
    }

    public handlePage(e: any) {
      this.currentPage = e.pageIndex;
      this.pageSize = e.pageSize;
      this.iterator();
    }

    private iterator() {
      const end = (this.currentPage + 1) * this.pageSize;
      const start = this.currentPage * this.pageSize;
      const part = this.userList.slice(start, end);
      this.dataSource = part;
    }
  saveUserData(){

    console.log(this.user.value)
    // if (!this.user.valid) {
    //   console.log('All fields are required.')
    //   return false;
    // } 
    if(this.user.status == 'INVALID'){
      this.user.markAllAsTouched();
      Swal.fire('','Please fill all mandatory fields','error');
      return false;
    }
    else {
    if(this.buttonName == 'Save'){
    this.modal.nativeElement.click();
    this.userservice.saveUsers(this.user.value,this.image).subscribe(result=>{
      this.getUserList();

    })
  }else{
    this.userservice.updateUsers(this.user.value,this.image).subscribe(result=>{
      this.modal.nativeElement.click();
      this.user.reset();
      this.getUserList();

    })
  }
}
  }

  getUserList(){
    this.userservice.userList().subscribe(result=>{
      
      this.dataSource = new MatTableDataSource<any>(result.data);

      this.dataSource.paginator = this.paginator;
      this.userList=result.data
      this.totalSize = this.userList.length;
      this.iterator();

    })
  }
  checkValidInputData(event: any,type) {
    var pattern:any;
    if(type=='1'){
        pattern = /[0-9\+\-\ ]/;//validae only number
    }
    if(type=='2'){
       pattern= /^[0-9]*\.?[0-9]*$/;//validae decimal with number 
    }
    if(type=='3'){
      pattern= /^[A-Za-z0-9]+$/;//validae alphanumeric 
    }
    if(type=='4'){
      pattern= /^[A-Za-z0-9 ]+$/;//validae alphanumeric with space
    
    }
    if(type=='5'){
      pattern = /^[(\d{3}\)\s?\d{3}-\d{4}]+$/;
     // pattern = /[0-9\+\/\ ]/;//validae only number with \
    }
    if(type == '6'){
        pattern= /^['A-Za-z0-9\-\\.\\/\']+$/; 
    }
    if(type=='7'){
      pattern= /^[A-Za-z ]+$/;//validae alphabets with space
     // pattern= /^['A-Za-z0-9 \-\ \.\ \:\ \;\ \_\ \%\ \#\ \@\ \%\']+$/;
    }
    if(type=='8'){
       pattern = /^[a-zA-Z \-\']+/; //Only alphabets
    }
    if(type=='10'){
        // pattern =  /^[A-Za-z0-9 #&()]+$/; //for location table
        pattern = /^[A-Za-z0-9#&()_., -]+$/;
    }
    if(type=='11'){
        pattern = /^[0-9 \,\']+$/;
    }
    if(type=='12'){
        pattern= /^['A-Za-z0-9 \-\ \.\ \(\ \)\ \\\\ \#\ \/\ \,\ \&\']+$/;//validae alphanumeric with space ^[a-zA-Z \-\ \.\']+ ............ADDRESS
    }
    if(type=='13'){
        pattern= /^['A-Za-z0-9 \-\ \.\ \&\ \/\']+$/;
    }
    if(type=='14'){
        pattern= /^['A-Za-z0-9  \,\  \/]+$/;
    }
    if(type=='15'){
        pattern= /^['A-Za-z0-9  \,\  \/\#\-\.]+$/;
    }
    if(type=='16'){
      pattern= /^[A-Z]/;
    }
    if(type=='17'){
      pattern= /^['A-Za-z \-\ \/\. ]/ ;
    }
    
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  editUser(id){
    if(id == "add"){
      this.buttonName='Save';
      this.user.reset();
      this.image="";
      this.file_name="Choose Profile Picture";
    }else{
    console.log(id)
    this.buttonName='Update'
 this.userservice.editUser(id).subscribe(result=>{
   this.editedData =result.data[0];
   this.user.get('name').setValue(this.editedData.name)
   this.user.get('mobile_number').setValue(this.editedData.mobile_number)
   this.user.get('image').setValue(this.editedData.image)
   this.user.get('id').setValue(this.editedData.id)
 
   this.image=this.editedData.image;
 })
}
 
   }
  onFileChange(event){
    // alert(1)
    const formData = new FormData();
    formData.append('file', event.target.files[0]);

    this.productservice.uploadDocuments(formData).subscribe(result=>{
      this.image=result.image_path;
      this.file_name=result.file_name;
      // console.log(this.image)

    })
  }

  deleteUser(id){
    console.log(id)
    Swal.fire({
      title: "Are you sure?",
      text: "you want to Delete it?",
      // type: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No"
    }).then(result => {
      if (result.value) {
        this.userservice.deleteUser(id).subscribe(result=>{
          this.getUserList();
        })
    }else if (result.dismiss === Swal.DismissReason.cancel) {
      Swal.fire("Cancelled","", "error");
    }
        
                });
  }
  handleUserStatus(id, status)
  {
    if(status == 1)
    {
      this.deActiveUser(id)
    }
    else{
      this.isActiveUser(id)
    }
    
  }

  handleBolckUserStatus(id, status)
  {
    if(status == 1)
    {
      this.userUnBlock(id)
    }
    else{
      
      this.userBlock(id)
    }
    
  }

  deActiveUser(id){
    console.log(id)
    Swal.fire({
      title: "Are you sure?",
      text: "you want to Deactive User?",
      // type: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No"
    }).then(result => {
      if (result.value) {
        this.userservice.deActiveUser(id).subscribe(result=>{
          this.getUserList();
        })
    }else if (result.dismiss === Swal.DismissReason.cancel) {
      Swal.fire("Cancelled","", "error");
    }
        
                });
  }

  isActiveUser(id){
    console.log(id)
    Swal.fire({
      title: "Are you sure?",
      text: "you want to Active User?",
      // type: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No"
    }).then(result => {
      if (result.value) {
        this.userservice.isActiveUser(id).subscribe(result=>{
          this.getUserList();
        })
    }else if (result.dismiss === Swal.DismissReason.cancel) {
      Swal.fire("Cancelled","", "error");
    }
        
                });
  }


  userUnBlock(id){
    console.log(id)
    Swal.fire({
      title: "Are you sure?",
      text: "you want to UnBlock User?",
      // type: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No"
    }).then(result => {
      if (result.value) {
        this.userservice.userUnBlock(id).subscribe(result=>{
          this.getUserList();
        })
    }else if (result.dismiss === Swal.DismissReason.cancel) {
      Swal.fire("Cancelled","", "error");
    }
        
                });
  }

  userBlock(id){
    console.log(id)
    Swal.fire({
      title: "Are you sure?",
      text: "you want to Block User?",
      // type: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No"
    }).then(result => {
      if (result.value) {
        this.userservice.userBlock(id).subscribe(result=>{
          this.getUserList();
        })
    }else if (result.dismiss === Swal.DismissReason.cancel) {
      Swal.fire("Cancelled","", "error");
    }
        
                });
  }
}
