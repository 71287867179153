import { Component, OnInit,ViewChild ,ElementRef} from '@angular/core';
import { ProductService } from 'app/service/products.service';
import { FormBuilder, FormGroup, Validators, FormArray,FormControl } from '@angular/forms';
import Swal from 'sweetalert2';
import {Observable, Subject} from 'rxjs';
import {map, startWith, takeUntil} from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';
import { UserService } from 'app/service/user.service';
interface AssignUsers {

  value: number;
  label: string;

}
export class App {
  constructor(
    public name: string,
    public color: string,
    public icon: string,
    public description: string
  ) {}
}

@Component({
  selector: 'app-bestreport',
  templateUrl: './bestreport.component.html',
  styleUrls: ['./bestreport.component.css']
})
export class BestreportComponent implements OnInit {
  

  productData: any;
  productData1: any[];
  userList: any;
  listFilterValue: any;
  project_assignmenttype = new FormControl();
  BestProduct: any;
  carpenterOfTheYear: any;
  carpenterOfTheMonth: any;
  bestProduct: any;
  bestCarpenterMonth: any;
  bestCarpenterYear: any;

  constructor(public productservice:ProductService,public formBuilder: FormBuilder,public userservice:UserService) { 
   

  }


  ngOnInit(): void {
    this.getProductList();

    this.getUserList();
    this.BestData();
    
  }

  

  getProductList(){
   
    this.productservice.productList().subscribe(result=>{
      this.productData=result.data; 
      
    })

  }

  getUserList(){
    this.userservice.userList().subscribe(result=>{
      this.userList=result.data

    })
  }

  BestData(){
    this.productservice.getBestData().subscribe(result=>{
     this.carpenterOfTheMonth=result.data1
     this.carpenterOfTheYear=result.data2
     this.BestProduct=result.data3
     this.bestProduct = (result.data3==null || result.data3=='' || result.data3==undefined)?0:this.BestProduct.id;
     this.bestCarpenterYear = (result.data2==null || result.data2=='' || result.data2==undefined)?0:this.carpenterOfTheYear.id;
     this.bestCarpenterMonth = (result.data1==null || result.data1=='' || result.data1==undefined)?0:this.carpenterOfTheMonth.id;
    console.log(this.bestCarpenterMonth)
    })
  }
  bestProductChange(val,type)
  {
    let data:any = {}
    if(type== 1 || type == 2)
    {
     data={
        "user_id":val,
        "type":type
      }
    }
    else{
    data={
        "product_id":val
      }
    }
    this.productservice.bestAssets(data).subscribe(result=>{
      this.productData=result.data; 
      this.getProductList();

      // this.getUserList();
      this.BestData();
    })

  }

}
