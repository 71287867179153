import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class JwtService {

  constructor(private httpClient: HttpClient) { }
 

  public redirectURL='';
  private loggedIn = false;

  public logout(): void {
    localStorage.clear();
    this.loggedIn = false;
  }

  public isLoggedIn(): boolean {
   console.log(1);
   var backend_token=localStorage.getItem('token');
   
   return (backend_token=='' || backend_token==null)?false:true;
         
  }


}
