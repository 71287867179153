import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {  } from 'rxjs/Rx';
import { catchError, tap, map } from 'rxjs/operators';
import {  of, throwError, Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(private httpClient: HttpClient) { }
  public handleError(error:any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
        // client-side error
        errorMessage = `Error: ${error.error.message}`;
    } else {
        // server-side error
        errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
}
public loginAuth(LoginForm:any):Observable<any> {

  return this.httpClient.post(`https://iaccountdev.com/iaccount_biz/user/ajaxValidateCredsAPI`,{LoginForm:LoginForm})
        .pipe(catchError(this.handleError), tap(res => {
          return res;

        }));
}
}
