import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { LoginService } from 'app/service/login.service';
import { ProductService } from 'app/service/products.service';
// import { LoginService } from 'src/app/service/login.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  // login :FormGroup;
  // login :FormGroup;
 
  login: FormGroup;
  // login: FormGroup | undefined;
  constructor(public productservice:ProductService,public formBuilder: FormBuilder,public _route: Router,public _login:LoginService) { }
 
  ngOnInit(): void {
 
    this.login = this.formBuilder.group({
      username:'',
      password:'',
    
    });
   
  }
  
  login1(){
    console.log(this.login)
    if(this.login.status == 'INVALID'){
      this.login.markAllAsTouched();
      Swal.fire('','Please fill all mandatory fields','error');
      return false;
    }
    else {
  let LoginForm={
    dev_token: null,
    password: this.login.value.password,
    username: this.login.value.username

  }
    // this._login.loginAuth(LoginForm).subscribe((res: any)=>
    //   {
    //     localStorage.setItem('token',res.data.access_token)
    //     this._route.navigateByUrl('Products');

    //   })

    // if(this.login.value.username == 'admin@123' &&  this.login.value.password == 'admin'){
    //   localStorage.setItem('token','serftgyhujkldtrfyhuokpesxfrtghyujiksedfrtghujik')
      
    // }
    console.log(LoginForm)
    this.productservice.Login(LoginForm).subscribe(result=>{
if(result.response_code == 1){
  localStorage.setItem('token',result.token)
  this._route.navigateByUrl('dashboard');

}else{
  Swal.fire('','Please fill Correct UserName And Password','error');
}
console.log(result)
      // 
      
    })
  }
  }
}