import { Injectable } from "@angular/core";
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanActivateChild
} from "@angular/router";

import { JwtService } from "../service/jwt.service";
// import { config } from './../config';
@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(private _jwtService: JwtService, private _router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    let url: string = state.url;
    return this.checkLogin(url);
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    return this.canActivate(route, state);
  }
   checkLogin(url: string): boolean {
    console.log
    if (this._jwtService.isLoggedIn()) {
         return true;
    }

    // Store the attempted URL for redirecting
    this._jwtService.redirectURL = url;

    // Navigate to the login page with extras
    //this._router.navigate(["/login"], { queryParams: { r: url } });
   // window.location.href = 'https://www.google.com/';
  this._router.navigate(["/login"]);
    //  window.location.href = '/login';
    return false;
  }
 
}
