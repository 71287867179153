import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {  } from 'rxjs/Rx';
import{config}from './../config';
import { catchError, tap, map } from 'rxjs/operators';
import {  of, throwError, Observable } from 'rxjs';
import { data } from 'jquery';
@Injectable({
  providedIn: 'root'
})
export class ProductService {

  constructor(private httpClient: HttpClient) { }
  public handleError(error:any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
        // client-side error
        errorMessage = `Error: ${error.error.message}`;
    } else {
        // server-side error
        errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
}
// public loginAuth(LoginForm:any):Observable<any> {

//   return this.httpClient.post(`https://iaccountdev.com/iaccount_biz/user/ajaxValidateCredsAPI`,{LoginForm:LoginForm})
//         .pipe(catchError(this.handleError), tap(res => {
//           return res;

//         }));
// }
public productList():Observable<any> {

  return this.httpClient.post(`${config.apiUrl}User/getProductData`,{})
        .pipe(catchError(this.handleError), tap(res => {
          return res;

        }));
}
public getNotificationData():Observable<any> {

  return this.httpClient.post(`${config.apiUrl}User/getNotificationData`,{})
        .pipe(catchError(this.handleError), tap(res => {
          return res;

        }));
}
public SaveProduct(data,img,img1):Observable<any> {

  return this.httpClient.post(`${config.apiUrl}Login/saveProductData`,{product_name:data.product_name,product_name_hindi:data.product_name_hindi,product_details_hindi:data.product_details_hindi,product_descreption:data.product_descreption,product_link:data.product_link,product_points:data.product_points,product_price:data.product_price,product_image:img,product_image1:img1})
        .pipe(catchError(this.handleError), tap(res => {
          return res;

        }));
}

public createNotification(data,date,img):Observable<any> {

  return this.httpClient.post(`${config.apiUrl}User/createNotification`,{user_id:data.Product,all_user:data.allUser,title:data.title,message:data.message,date:date,hour:data.hour,minute:data.minute,nowSchedule:data.nowSchedule,image:img})
        .pipe(catchError(this.handleError), tap(res => {
          return res;

        }));
}
public bestAssets(data):Observable<any> {


  return this.httpClient.post(`${config.apiUrl}User/bestAssets`,data)
        .pipe(catchError(this.handleError), tap(res => {
          return res;

        }));
}
public updateproduct(data,img,img1):Observable<any> {

  return this.httpClient.post(`${config.apiUrl}Login/updateProductData`,{product_name:data.product_name,product_descreption:data.product_descreption,product_points:data.product_points,product_price:data.product_price,product_link:data.product_link,product_image:img,product_image1:img1,id:data.id})
        .pipe(catchError(this.handleError), tap(res => {
          return res;

        }));
}
public uploadDocuments(formData):Observable<any> {

    return this.httpClient.post(`${config.apiUrl}User/upload`,formData)
          .pipe(catchError(this.handleError), tap(res => {
            return res;
  
          }));
  }
  public editproduct(id):Observable<any> {

    return this.httpClient.post(`${config.apiUrl}Login/getProductDataById`,{id:id})
          .pipe(catchError(this.handleError), tap(res => {
            return res;
  
        }));
}
  public deleteProduct(id):Observable<any> {

    return this.httpClient.post(`${config.apiUrl}Login/removeProductData`,{id:id})
          .pipe(catchError(this.handleError), tap(res => {
            return res;
  
        }));
}
  public qrCode(id):Observable<any> {

    return this.httpClient.post(`${config.apiUrl}User/genrateQrcode`,{id:id})
          .pipe(catchError(this.handleError), tap(res => {
            return res;
  
        }));
}

public giftList():Observable<any> {

  return this.httpClient.post(`${config.apiUrl}User/getGiftData`,{})
        .pipe(catchError(this.handleError), tap(res => {
          return res;

        }));
}


public GiftProduct(data,img):Observable<any> {

  return this.httpClient.post(`${config.apiUrl}User/saveGiftData`,{product_name:data.gift_name,product_name_hindi:data.gift_name_hindi,product_details_hindi:data.gift_descreption_hindi,details:data.gift_descreption,points:data.gift_points,image:img})
        .pipe(catchError(this.handleError), tap(res => {
          return res;

        }));
}

public deleteGift(id):Observable<any> {

  return this.httpClient.post(`${config.apiUrl}Login/removeGiftData`,{id:id})
        .pipe(catchError(this.handleError), tap(res => {
          return res;

      }));
}

public editGift(id):Observable<any> {

  return this.httpClient.post(`${config.apiUrl}User/getGiftDataById`,{id:id})
        .pipe(catchError(this.handleError), tap(res => {
          return res;

      }));
}

public updateGift(data,img):Observable<any> {

  return this.httpClient.post(`${config.apiUrl}Login/updateGiftData`,{product_name:data.gift_name,details:data.gift_descreption,points:data.gift_points,image:img,id:data.id})
        .pipe(catchError(this.handleError), tap(res => {
          return res;

        }));
}

public Login(data):Observable<any> {

  return this.httpClient.post(`${config.apiUrl}User/Admin_login_user`,{user_name:data.username,password:data.password})
        .pipe(catchError(this.handleError), tap(res => {
          return res;

      }));
}

public getGiftHistoryList():Observable<any> {

  return this.httpClient.post(`${config.apiUrl}User/getHistoryOfGiftDataForAdmin`,{})
        .pipe(catchError(this.handleError), tap(res => {
          return res;

        }));
}

public AccceptGift(id):Observable<any> {

  return this.httpClient.post(`${config.apiUrl}User/acceptGiftRequest`,{id:id})
        .pipe(catchError(this.handleError), tap(res => {
          return res;

      }));
}

public RejectGift(id):Observable<any> {

  return this.httpClient.post(`${config.apiUrl}User/rejectGiftRequest`,{id:id})
        .pipe(catchError(this.handleError), tap(res => {
          return res;

      }));
}

public Gifted(id):Observable<any> {

  return this.httpClient.post(`${config.apiUrl}User/gift`,{id:id})
        .pipe(catchError(this.handleError), tap(res => {
          return res;

      }));
}

public whatsNew():Observable<any> {

  return this.httpClient.post(`${config.apiUrl}User/getWhatsNewData`,{})
        .pipe(catchError(this.handleError), tap(res => {
          return res;

        }));
}

public deleteHistory(id):Observable<any> {

  return this.httpClient.post(`${config.apiUrl}User/removeHistoryData`,{id:id})
        .pipe(catchError(this.handleError), tap(res => {
          return res;

      }));
}
public deleteNotification(id):Observable<any> {

  return this.httpClient.post(`${config.apiUrl}User/deleteNotification`,{id:id})
        .pipe(catchError(this.handleError), tap(res => {
          return res;

      }));
}
public userCount():Observable<any> {

  return this.httpClient.post(`${config.apiUrl}User/getuserCount`,{})
        .pipe(catchError(this.handleError), tap(res => {
          return res;

        }));
}

public ProductCount():Observable<any> {

  return this.httpClient.post(`${config.apiUrl}User/getProductCount`,{})
        .pipe(catchError(this.handleError), tap(res => {
          return res;

        }));
}

public giftCount():Observable<any> {

  return this.httpClient.post(`${config.apiUrl}User/getGiftCount`,{})
        .pipe(catchError(this.handleError), tap(res => {
          return res;

        }));
}

public whatsNewCount():Observable<any> {

  return this.httpClient.post(`${config.apiUrl}User/getWhatsNewCount`,{})
        .pipe(catchError(this.handleError), tap(res => {
          return res;

        }));
}
public getTotalPointsOfUser():Observable<any> {

  return this.httpClient.post(`${config.apiUrl}User/getTotalPointsOfUser`,{})
        .pipe(catchError(this.handleError), tap(res => {
          return res;

        }));
}
public getTotalRedeemPointsOfUser():Observable<any> {

  return this.httpClient.post(`${config.apiUrl}User/getTotalRedeemPointsOfUser`,{})
        .pipe(catchError(this.handleError), tap(res => {
          return res;

        }));
}

public getBestData():Observable<any> {

  return this.httpClient.post(`${config.apiUrl}Login/userOfTheMonth`,{})
        .pipe(catchError(this.handleError), tap(res => {
          return res;

        }));
}

public getProductRedeem():Observable<any> {

  return this.httpClient.post(`${config.apiUrl}User/getProductRedeem`,{})
        .pipe(catchError(this.handleError), tap(res => {
          return res;

        }));
}

public deletereward(id):Observable<any> {

  return this.httpClient.post(`${config.apiUrl}User/deletereward`,{id:id})
        .pipe(catchError(this.handleError), tap(res => {
          return res;

      }));
}

public saveqrCode(data):Observable<any> {

  return this.httpClient.post(`${config.apiUrl}User/saveqrCode`,{Product:data.Product,quantity:data.quantity})
        .pipe(catchError(this.handleError), tap(res => {
          return res;

        }));
}

public getQrCodeData():Observable<any> {

  return this.httpClient.post(`${config.apiUrl}User/getQrCodeHistoryData`,{})
        .pipe(catchError(this.handleError), tap(res => {
          return res;

        }));
}

public deleteQrCode(id):Observable<any> {

  return this.httpClient.post(`${config.apiUrl}User/deleteQrCode`,{id:id})
        .pipe(catchError(this.handleError), tap(res => {
          return res;

      }));
}

public qrQrCodeDataById(id):Observable<any> {

  return this.httpClient.post(`${config.apiUrl}User/getQrCodeDataById`,{id:id})
        .pipe(catchError(this.handleError), tap(res => {
          return res;

      }));
}
}
