import { Component, OnInit,ViewChild ,ElementRef} from '@angular/core';
import { ProductService } from 'app/service/products.service';
import { FormBuilder, FormGroup, Validators, FormArray,FormControl } from '@angular/forms';
import Swal from 'sweetalert2';
import {saveAs as importedSaveAs} from "file-saver";
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}
const ELEMENT_DATA: PeriodicElement[] = [
  
];

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.css']
})

export class ProductsComponent implements OnInit {
  @ViewChild('modal') modal;
  public pageSize = 10;
public currentPage = 0;
public totalSize = 0;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  productData: any;
  pagesize: number;
  pageindex: any;
  Product:FormGroup;
  image: any;
  image1: any=[];
  
  editedData: any;
  zeditedData: any;
 buttonName='Save'
  fileName: any;
  file_name='Choose Main Product Image';
  file_name_for_multiple='Choose Multiple Product Image';
  displayedColumns: string[] = ['position','Product Image', 'Product Name', 'Product Name In Hindi', 'Product Description', 'Product Description In Hindi', 'Product Price', 'Product Points', 'Created Date','Action'];
  // this.dataSource = new MatTableDataSource<any>(this.taskExpenseList);
  dataSource = new MatTableDataSource(ELEMENT_DATA);
  image_path: any;
  imageData: any = [];
  
  constructor(public productservice:ProductService,public formBuilder: FormBuilder,) { }

  ngOnInit(): void {
    this.dataSource.paginator = this.paginator;

    this.Product = this.formBuilder.group({
      id:'',
      product_name:['',Validators.required],
      product_descreption:['',Validators.required],
      product_name_hindi:['',Validators.required],
      product_details_hindi:['',Validators.required],
      product_price : ['',Validators.required],
      product_points: ['',[Validators.required]],
      product_image: [''],
      multiple_product_image: [''],
      product_link : ['',Validators.required],
     
    });
    this.getProductList();
  }

  public handlePage(e: any) {
    this.currentPage = e.pageIndex;
    this.pageSize = e.pageSize;
    this.iterator();
  }

  private iterator() {
    const end = (this.currentPage + 1) * this.pageSize;
    const start = this.currentPage * this.pageSize;
    const part = this.productData.slice(start, end);
    this.dataSource = part;
  }
  getProductList(){
    // this.buttonName == 'Save'
    this.productservice.productList().subscribe(result=>{
      // this.productData=result.data;
       this.dataSource = new MatTableDataSource<any>(result.data);

       this.dataSource.paginator = this.paginator;
      this.productData=result.data
      this.totalSize = this.productData.length;
      this.iterator();

    })

  }

  onFileChange(event){
    
      const formData = new FormData();
      formData.append('file', event.target.files[0]);
  
      this.productservice.uploadDocuments(formData).subscribe(result=>{
        
        this.image=result.image_path
        console.log(this.image)
        this.file_name=result.file_name;
        // console.log(this.image)
  
      })
   
   
  }

  onFileChangeForMultiple(event){
    console.log(event.target.files)
    let count = event.target.files.length - 1
    for(let i=0;i<=count;i++ ){
      const formData = new FormData();
      formData.append('file', event.target.files[i]);
  
      this.productservice.uploadDocuments(formData).subscribe(result=>{
         this.image_path = result.image_path
        this.image1.push(this.image_path);
        console.log(this.image)
        this.file_name_for_multiple=result.file_name;
        // console.log(this.image)
  
      })
    }
   
  }

  saveProduct(){
    // if (!this.Product.valid) {
    //   console.log('All fields are required.')
    //   return false;
    // } 
    if(this.Product.status == 'INVALID'){
      this.Product.markAllAsTouched();
      Swal.fire('','Please fill all mandatory fields','error');
      return false;
    }
    else {
  if(this.buttonName == 'Save'){
    this.productservice.SaveProduct(this.Product.value,this.image1,this.image).subscribe(result=>{
      this.modal.nativeElement.click();
      this.Product.reset();
      this.getProductList();

    })
  }else{
    this.productservice.updateproduct(this.Product.value,this.image1,this.image).subscribe(result=>{
      this.modal.nativeElement.click();
      this.Product.reset();
      this.getProductList();

    })
  }
}
    

  }
  removeImg(i){
    // alert(i)
    this.image1.splice(i,1)
    
  }
  editProduct(id){
    console.log(id)

    if(id == "add"){
      this.buttonName='Save';
      this.Product.reset();
      this.image="";
      this.image1=[];
      this.image_path="";
      this.file_name="Choose Main Product Image";
      this.file_name_for_multiple="Choose Multiple Product Image";
      
    }else{
   console.log(id)
   this.image="";
      this.image1=[];
      this.image_path="";
   this.buttonName='Update'
this.productservice.editproduct(id).subscribe(result=>{
  this.editedData =result.data[0];
  this.imageData =result.multiple_image;
  this.imageData.forEach(element => {
    this.image1.push(element.product_image)
    
  });
  this.Product.get('product_name').setValue(this.editedData.product_name)
  this.Product.get('product_descreption').setValue(this.editedData.product_descreption)
  this.Product.get('product_name_hindi').setValue(this.editedData.product_name_hindi)
  this.Product.get('product_details_hindi').setValue(this.editedData.product_details_hindi)
  this.Product.get('product_price').setValue(this.editedData.product_price)
  this.Product.get('product_points').setValue(this.editedData.product_points)
  this.Product.get('product_image').setValue(this.editedData.product_image)
  this.Product.get('product_link').setValue(this.editedData.product_link)
  this.Product.get('id').setValue(this.editedData.id)
  this.image=this.editedData.product_image;
})
}
  }
  qrCode(id){
   console.log(id)
  
     this.productservice.qrCode(id).subscribe(result=>{
      console.log(result)
       this.fileName =result.fileName;
})
  }

  checkValidInputData(event: any,type) {
    var pattern:any;
    if(type=='1'){
        pattern = /[0-9\+\-\ ]/;//validae only number
    }
    if(type=='2'){
       pattern= /^[0-9]*\.?[0-9]*$/;//validae decimal with number 
    }
    if(type=='3'){
      pattern= /^[A-Za-z0-9]+$/;//validae alphanumeric 
    }
    if(type=='4'){
      pattern= /^[A-Za-z0-9 ]+$/;//validae alphanumeric with space
    
    }
    if(type=='5'){
      pattern = /^[(\d{3}\)\s?\d{3}-\d{4}]+$/;
     // pattern = /[0-9\+\/\ ]/;//validae only number with \
    }
    if(type == '6'){
        pattern= /^['A-Za-z0-9\-\\.\\/\']+$/; 
    }
    if(type=='7'){
      pattern= /^[A-Za-z ]+$/;//validae alphabets with space
     // pattern= /^['A-Za-z0-9 \-\ \.\ \:\ \;\ \_\ \%\ \#\ \@\ \%\']+$/;
    }
    if(type=='8'){
       pattern = /^[a-zA-Z \-\']+/; //Only alphabets
    }
    if(type=='10'){
        // pattern =  /^[A-Za-z0-9 #&()]+$/; //for location table
        pattern = /^[A-Za-z0-9#&()_., -]+$/;
    }
    if(type=='11'){
        pattern = /^[0-9 \,\']+$/;
    }
    if(type=='12'){
        pattern= /^['A-Za-z0-9 \-\ \.\ \(\ \)\ \\\\ \#\ \/\ \,\ \&\']+$/;//validae alphanumeric with space ^[a-zA-Z \-\ \.\']+ ............ADDRESS
    }
    if(type=='13'){
        pattern= /^['A-Za-z0-9 \-\ \.\ \&\ \/\']+$/;
    }
    if(type=='14'){
        pattern= /^['A-Za-z0-9  \,\  \/]+$/;
    }
    if(type=='15'){
        pattern= /^['A-Za-z0-9  \,\  \/\#\-\.]+$/;
    }
    if(type=='16'){
      pattern= /^[A-Z]/;
    }
    if(type=='17'){
      pattern= /^['A-Za-z \-\ \/\. ]/ ;
    }
    
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
  downloadFile(id) {
    this.productservice.qrCode(id).subscribe(result=>{
      console.log(result)
       this.fileName =result.fileName;
      //  const blob = new Blob([ this.fileName], { type: 'text/png' });
      //  const url= window.URL.createObjectURL(blob);
      //  window.open( this.fileName);
      importedSaveAs(this.fileName , 'filename');
      
})
  }

  toDataURL(url) {
    return fetch(url)
      .then(response => {
        return response.blob();
      })
      .then(blob => {
        return URL.createObjectURL(blob);
      });
  }

  async download(id) {
    this.productservice.qrCode(id).subscribe(async result=>{
      console.log(result)
       this.fileName =result.fileName;
    const a = document.createElement("a");
    a.href = await this.toDataURL(this.fileName);
    a.download = "test.png";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  })
  }
  deleteProduct(id){

    Swal.fire({
      title: "Are you sure?",
      text: "you want to Delete it?",
      // type: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No"
    }).then(result => {
      if (result.value) {
        this.productservice.deleteProduct(id).subscribe(result=>{
          this.getProductList();
        })
    }else if (result.dismiss === Swal.DismissReason.cancel) {
      Swal.fire("Cancelled","", "error");
    }
        
                });

    

  }

 
  

}
