import { Component, OnInit,ViewChild ,ElementRef} from '@angular/core';
import { ProductService } from 'app/service/products.service';
import { FormBuilder, FormGroup, Validators, FormArray,FormControl } from '@angular/forms';
import Swal from 'sweetalert2';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
export interface PeriodicElement {
  giftName: string;
  giftpoints: number;
  giftimage: string;
  status: string;

}

const ELEMENT_DATA: PeriodicElement[] = [
  
];
@Component({
  selector: 'app-gift-history',
  templateUrl: './gift-history.component.html',
  styleUrls: ['./gift-history.component.css']
})
export class GiftHistoryComponent implements OnInit {
  @ViewChild('modal') modal;
  public pageSize = 10;
  public currentPage = 0;
  public totalSize = 0;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
  productData: any;
  Gift:FormGroup;
  image: any;
  editedData: any;
 buttonName='Save'
  fileName: any;
  file_name='Choose File';
  displayedColumns: string[] = ['position','Gift Image', 'Gift Name','User Name', 'Gift Points', 'Status', 'Action'];
  dataSource = new MatTableDataSource(ELEMENT_DATA);
  searchKey: string;
  
  constructor(public productservice:ProductService) { }

  ngOnInit(): void {
    this.getGiftHistoryList();
    this.dataSource.paginator = this.paginator;
    
  }
  
  // applyFilter(filterValue: string) {
  //   console.log(filterValue)
  //   filterValue = filterValue.trim(); // Remove whitespace
  //   filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
  //   this.dataSource.filter = filterValue;

  //   console.log(this.dataSource.filter)
  // }
  // onSearchClear(){
  //   this.searchKey="";
  //   this.applyFilter();
  // }

  // applyFilter(){
  //   this.dataSource.filter = this.searchKey.trim().toLowerCase();
  // }
  public handlePage(e: any) {
    this.currentPage = e.pageIndex;
    this.pageSize = e.pageSize;
    this.iterator();
  }
  private iterator() {
    const end = (this.currentPage + 1) * this.pageSize;
    const start = this.currentPage * this.pageSize;
    const part = this.productData.slice(start, end);
    this.dataSource = part;
  }
  getGiftHistoryList(){
    this.buttonName == 'Save'
    this.productservice.getGiftHistoryList().subscribe(result=>{
      // result.data.forEach(element => {
      //   ELEMENT_DATA.push(element)
      // });
     
      // console.log(ELEMENT_DATA)
      this.dataSource = new MatTableDataSource<any>(result.data);

      this.dataSource.paginator = this.paginator;
     this.productData=result.data;
     this.totalSize = this.productData.length;
     this.iterator();

    })

  }
  AccceptGift(id){
    console.log(id)
    Swal.fire({
      title: "Are you sure?",
      text: "you want to Accept this Gift?",
      // type: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No"
    }).then(result => {
      if (result.value) {
        this.productservice.AccceptGift(id).subscribe(result=>{
          this.getGiftHistoryList();
        })
    }else if (result.dismiss === Swal.DismissReason.cancel) {
      Swal.fire("Cancelled","", "error");
    }
        
                });
  }

  RejectGift(id){
    console.log(id)
    Swal.fire({
      title: "Are you sure?",
      text: "you want to Reject this Gift?",
      // type: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No"
    }).then(result => {
      if (result.value) {
        this.productservice.RejectGift(id).subscribe(result=>{
          this.getGiftHistoryList();
        })
    }else if (result.dismiss === Swal.DismissReason.cancel) {
      Swal.fire("Cancelled","", "error");
    }
        
                });
  }

  Gifted(id){
    console.log(id)
    Swal.fire({
      title: "Are you sure?",
      text: "you want to Gift it?",
      // type: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No"
    }).then(result => {
      if (result.value) {
        this.productservice.Gifted(id).subscribe(result=>{
          this.getGiftHistoryList();
        })
    }else if (result.dismiss === Swal.DismissReason.cancel) {
      Swal.fire("Cancelled","", "error");
    }
        
                });
  }

  deleteProduct(id){

    Swal.fire({
      title: "Are you sure?",
      text: "you want to Delete it?",
      // type: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No"
    }).then(result => {
      if (result.value) {
        this.productservice.deleteHistory(id).subscribe(result=>{
          this.getGiftHistoryList();
        })
    }else if (result.dismiss === Swal.DismissReason.cancel) {
      Swal.fire("Cancelled","", "error");
    }
        
                });

    

  }
}
