import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {  } from 'rxjs/Rx';
import{config}from './../config';
import { catchError, tap, map } from 'rxjs/operators';
import {  of, throwError, Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private httpClient: HttpClient) { }
  public handleError(error:any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
        // client-side error
        errorMessage = `Error: ${error.error.message}`;
    } else {
        // server-side error
        errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
}
// public loginAuth(LoginForm:any):Observable<any> {

//   return this.httpClient.post(`https://iaccountdev.com/iaccount_biz/user/ajaxValidateCredsAPI`,{LoginForm:LoginForm})
//         .pipe(catchError(this.handleError), tap(res => {
//           return res;

//         }));
// }
public saveUsers(data,img):Observable<any> {

  return this.httpClient.post(`${config.apiUrl}Login/saveUserData`,{name:data.name,mobile_number:data.mobile_number,location:data.location,password:data.password,image:img})
        .pipe(catchError(this.handleError), tap(res => {
          return res;

        }));
}
public updateUsers(data,img):Observable<any> {

  return this.httpClient.post(`${config.apiUrl}Login/updateUserData`,{name:data.name,mobile_number:data.mobile_number,location:data.location,password:data.password,image:img,id:data.id})
        .pipe(catchError(this.handleError), tap(res => {
          return res;

        }));
}
public userList():Observable<any> {

  return this.httpClient.post(`${config.apiUrl}Login/getUserData`,{})
        .pipe(catchError(this.handleError), tap(res => {
          return res;

        }));
}


public deleteUser(id):Observable<any> {

  return this.httpClient.post(`${config.apiUrl}Login/removeUserData`,{id:id})
        .pipe(catchError(this.handleError), tap(res => {
          return res;

      }));
}
public userUnBlock(id):Observable<any> {

  return this.httpClient.post(`${config.apiUrl}Login/userUnBlock`,{id:id})
        .pipe(catchError(this.handleError), tap(res => {
          return res;

      }));
}
public userBlock(id):Observable<any> {

  return this.httpClient.post(`${config.apiUrl}Login/userBlock`,{id:id})
        .pipe(catchError(this.handleError), tap(res => {
          return res;

      }));
}

public deActiveUser(id):Observable<any> {

  return this.httpClient.post(`${config.apiUrl}Login/userDeactive`,{id:id})
        .pipe(catchError(this.handleError), tap(res => {
          return res;

      }));
}
public isActiveUser(id):Observable<any> {

  return this.httpClient.post(`${config.apiUrl}Login/userActive`,{id:id})
        .pipe(catchError(this.handleError), tap(res => {
          return res;

      }));
}
public editUser(id):Observable<any> {

  return this.httpClient.post(`${config.apiUrl}User/geteditUserDataById`,{id:id})
        .pipe(catchError(this.handleError), tap(res => {
          return res;

      }));
}

}
