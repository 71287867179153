import { Component, OnInit,ViewChild ,ElementRef} from '@angular/core';
import { ProductService } from 'app/service/products.service';
import { FormBuilder, FormGroup, Validators, FormArray,FormControl } from '@angular/forms';
import Swal from 'sweetalert2';
import { Pipe, PipeTransform } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}
const ELEMENT_DATA: PeriodicElement[] = [
  
];
@Component({
  selector: 'app-whatsnew',
  templateUrl: './whatsnew.component.html',
  styleUrls: ['./whatsnew.component.css']
})
export class WhatsnewComponent implements OnInit {
  @ViewChild('modal') modal;
  public pageSize = 10;
  public currentPage = 0;
  public totalSize = 0;
    @ViewChild(MatPaginator) paginator: MatPaginator;
  searchText;
  productData: any;
  Product:FormGroup;
  image: any;
  editedData: any;
 buttonName='Save'
  fileName: any;
  file_name='Choose File';
  displayedColumns: string[] = ['position','Product Image', 'Product Name', 'Product Description', 'Product Price','Product Points', 'Action'];
  dataSource = new MatTableDataSource(ELEMENT_DATA);
  constructor(public productservice:ProductService) { }

  ngOnInit(): void {
    this.dataSource.paginator = this.paginator;
    this.getProductList();
  }

  public handlePage(e: any) {
    this.currentPage = e.pageIndex;
    this.pageSize = e.pageSize;
    this.iterator();
  }
  private iterator() {
    const end = (this.currentPage + 1) * this.pageSize;
    const start = this.currentPage * this.pageSize;
    const part = this.productData.slice(start, end);
    this.dataSource = part;
  }
  getProductList(){
    this.buttonName == 'Save'
    this.productservice.whatsNew().subscribe(result=>{
      this.dataSource = new MatTableDataSource<any>(result.data);

       this.dataSource.paginator = this.paginator;
      this.productData=result.data;
      this.totalSize = this.productData.length;
      this.iterator();

    })

  }

  deleteProduct(id){

    Swal.fire({
      title: "Are you sure?",
      text: "you want to Delete it?",
      // type: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No"
    }).then(result => {
      if (result.value) {
        this.productservice.deleteProduct(id).subscribe(result=>{
          this.getProductList();
        })
    }else if (result.dismiss === Swal.DismissReason.cancel) {
      Swal.fire("Cancelled","", "error");
    }
        
                });

    

  }
}
